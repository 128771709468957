<template>
  <div class="home">
  <div class="content">
      <section class="hero-section">
        <h1>【1】普通人必须实现财富自由，毫无选择</h1><br>
        <p>
          介绍一个诺奖级别的研究成果和演示基于该理论的工具。<br><br>
          帮助普通人在不占用过多精力的情况下，使用Excel进行科学的投资。
        </p>
      </section>
    <div>
      <section class="problems-section">
      <h2>免责声明</h2>
      <hr>
      <p>本教程仅限于介绍<strong>现代投资组合理论——均值方差分析</strong>和演示<strong>Excel工具</strong>进行相应计算，不做任何投资建议。</p>
      <ul>
        <li>涉及的任何数据都需谨慎验证</li>
        <li>投资有风险，入市需谨慎</li>
        <li>请在法定交易场所投资，远离非法交易活动</li>
      </ul>
      <p>本文提及的任何内容<strong>均不构成对投资产品的投资邀约，也不构成对于任何证券的买卖或进行任何交易的投资建议。</strong></p>
      <p>在使用本文所载资料行事或受其影响之前，阁下应咨询独立专业意见。本文引用的原始数据亦可能存在错误，使用之前阁下应从权威数据库下载并咨询独立意见。</p>
      <p>由于本文针对特定的读者，可能存在使用金融术语、数学概念不当，数学推导过程可能不完备的情况。</p>
    </section>
    <section class="problems-section">
        <h3 style="text-align: center;">晨光笔记2021</h3>
        <p style="text-align: center;">正值“间隔年”的500强职业经理人 + 国家一级登山运动员，与您分享：【财务积累】干货 + 【户外旅行】攻略 <strong>——晨光</strong></p>
        <p style="text-align: center;"><strong>本期科学投资相应视频：</strong><br>
         <a :href="url" target="_blank">
           <img src="@/assets/1-1.png" class="image"/>
         </a>
        </p>
        <figure style="text-align: center;">
          <img src="https://i.postimg.cc/kM6Mgp40/image.jpg" alt="欢迎关注微信公众号《晨光笔记2021》，更多优质内容等您发掘">
          <figcaption><strong>欢迎关注微信公众号《晨光笔记2021》，更多优质内容等您发掘</strong></figcaption>
        </figure>
      </section>

    </div>

    <div>
      <section class="problems-section">
      <h2>2 <strong>被忽视的事实</strong>——普通人必须实现财富自由，没有选择权</h2>
      <hr>
      <p class="text">
        年初的时候，我上初中的女儿问我：“爸爸，我过去几年的压岁钱，一直存在银行。利率不好。我可不可以在支付宝上买一些基金，这样在我上大学以前，可以有更多的资金储备。”<br>
            我对她的目的给予了肯定。但对她想使用的方法，给予了否定。在我试图进一步跟她解释为什么不能在支付宝上选择基金，以及有什么更好的方法的时候遇到了困难<strong>——确实，一言难尽</strong>。<br>
            后来她又问我：怎样才能实现财务自由。我告诉她：<strong>财富自由不是一种选择，而是一种必须</strong>，尽早实现高质量生活的财务自由是对普通人稍微高一点的要求。<br>
            最后她问我，我是怎么做的，是否可以交给她。这句话倒是激发了我浓厚的意愿，希望能做一套14岁的孩子也能看懂的<strong>财务自由宝典</strong>或是<strong>个人理财避坑指南</strong>和<strong>个人自主从入门到精通</strong>。于是经过我和她商议，我会把针对普通人科学的投资方法做成教程，交付给她。<br>
            那么为什么我可以做这样的分享呢？<br>
        </p>
      <ul>
          <li><strong>第一：</strong><br>这个方法是最近5年我利用工作之余花了大力气啃完现代投资组合理论——均值方差分析后得到的，这是一个诺奖级别的理论，并通过了三年的时间验证，用数据证明<strong>切实可行</strong>，才与大家分享。<br>
            相对于原著中使用的抽象或者机构投资人才可以操作的方法，本教程落实到中国二级市场可以操作的产品。<br>
            相对于原著中复杂的计算，甚至涉及到了矩阵乘法和非线性方程求解，本教程落实到<strong>Excel的具体功能</strong>。<br>在我的实践中，我把理论落实到可以<strong>通过公开的数据库获取资料</strong>、<strong>使用Excel完成统计和计算</strong>、<strong>使用A股账号即可操作</strong>并获取收益的方法。换句话说，本教程关注的不仅是概念，还是可以提供现成的工具给大家。即理论可靠、实践可行。</li><br>
          <li>
            <strong>第二：</strong><br>我不是金融专业毕业生，深知大家学习的痛点，所以<strong>不会使用晦涩难懂的专业术语与大家交流</strong>。相比之下，目前可以找到的绝大多数教程都是从写论文或学术研究的角度讲解。<br>
            承担不可预测的风险。
            <br>
          </li><br>
          <li><strong>第三：</strong><br>我不是金融从业人员，<strong>和大家没有利益冲突</strong>。粗暴的讲，本文介绍的理论和方法就是专业投资者日常使用的工具之一。最为回报，他们的客户要向其支付1-2%甚至更高的资金管理费用，无论该产品是否挣钱。<br>
            所以金融从业人员多数是不会把这个<strong>行业秘密</strong>与他的客户分享的。试想客户学会，还要基金经理做什么？再次强调，这样解释客户和金融从业人员的利益冲突是过于简单粗暴的，但便于大家理解。<br>
            </li>
        </ul>
    </section>
    <section class="problems-section">
      <h2>最后谈谈一个关于财富自由的错误认识：</h2>
      <p class="text"><strong>诗和远方</strong>已经成为鸡汤小视频的标配，可是似乎大家都忘了这句话的出自许巍的歌—生活不止眼前的苟且。原词是“生活不止眼前的苟且，还有诗和远方的田野”。这一句作为副歌部分重复了三遍。第一次是母亲对作为儿子的“我”说，第二次是女人对作为男人的“我”说，第三次是“我”对自己儿子说。这三次重复，似乎是暗示：大家都早知道诗和远方的存在，却不得不应对<strong>眼前的苟且</strong>。日复一日，年复一年，一代人接着一代人。如此往复，永无止境。</p>
      <p class="text">文艺范儿歌颂诗和远方，裸辞者去旅行的也不只是传说。<strong>现实主义者会感叹诗和远方是财富自由者的专利</strong>。因为在现实主义者看来，“诗和远方”是一个与“财富自由”伴生的奢侈目标。</p>
      <p class="text">的确，确实有些人通过自己的努力实现了这个目标。然而，我想说：<strong>这样现实主义的想法还是过于乐观了</strong>。我们这辈子，一定会在某一时刻被“财富自由”，而不是主动财富自由。而<strong>多数人，在这一时刻依旧是“眼前的苟且”。极少数人能够去看看“诗和远方的田野”</strong>。</p>
      <p class="text">这个时刻叫做<strong>退休日</strong>。自退休日起，被动收入将支撑起你所有的开销。按照财富自由的定义，所有人在退休这一天都不得不财富自由了。而实际上过的好与坏，完全取决于个人的<strong>财务积累</strong>。</p>
        <h3 style="text-align: center;">研究显示，退休之后的生活水平，几乎取决于储蓄。</h3><br>
      <p class="text"><strong>这是一个颇为残酷事实：你以为财富自由是一个伟大而奢侈的目标，其实财富自由是你不得不面对的人生挑战</strong>。如何实现高质量生活的财富自由和尽早的达成这一目标是每一个普通人必须面对的难题。</p>
      <p class="text">诚然，打工和任何形式的主动收入，都是这一目标的必要、甚至是最重要的手段。但绝大多数人却忽视了<strong>积极、科学的利用投资创造被动收入</strong>，对于实现高质量的财务自由的重要意义。</p>
      <p class="text">更为残酷的是，很多人靠主动收入小有积蓄，却因为没有科学的投资方法被“骗”的损失惨重。对，你没有看错。多数人目前使用的理财手段，都是经专业手段包装过的骗局。也许<strong>骗局</strong>这个词有些过分了，但是在投资理财过程中，绝大多数人都在蒙受本不应承受的损失，这是一点也不夸张的。</p>
      <p class="text"><strong>而本文的主要目的正是：帮助小有积蓄的人，科学的自助理财。</strong></p>
    </section>
    <h1>to be continued</h1> </div>
</div>
</div>
</template>

<script>
// import MyPortfolioVsAssetsComparison from './MyPortfolioVsAssetsComparison.vue'
export default {
  name: 'VueOne',
  data () {
    return {
      url: 'https://www.bilibili.com/video/BV1634y1E7EZ/?spm_id_from=333.999.0.0&vd_source=dc968aa7423843f727e9d8f317282c0f',
      imagePath: require('@/assets/1-1.png')
    }
  }
}
</script>

<style scoped>
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  color: #333;
  background-color: #f5f5f7;
}

.text {
  font-size: 1.2em;
  line-height: 1.8;
  margin-bottom: 10px;
}

.home {
  text-align: center;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.hero-section {
  background: #fff;
  padding: 60px 20px;
  margin: 40px 0;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.hero-section h1 {
  font-size: 2.8em;
  margin-bottom: 20px;

}

.hero-section p {
  font-size: 1.4em;
  line-height: 1.8;
}

.problems-section,
.advantages-section,
.case-section,
.info-section {
  background: #fff;
  padding: 30px 10px;
  margin: 40px 0;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.problems-section h3,
.advantages-section h3,
.case-section h3,
.info-section h3 {
  font-size: 2.2em;
  margin-bottom: 20px;
  line-height: 1.5em;
}

.problems-section ul,
.advantages-section ul,
.case-section ul {
  list-style-type: disc;
  padding-left: 20px;
}

.problems-section li,
.advantages-section li,
.case-section li {
  font-size: 1.2em;
  line-height: 1.8;
  margin-bottom: 10px;
  list-style-type: disc;
  padding-left: 20px;
}

.products-section {
  background: #fff;
  padding: 60px 20px;
  margin: 40px 0;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.products-section h2 {
  font-size: 2.4em;
  margin-bottom: 20px;
}

.products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  min-height: 1000px;
}

.product-item {
  background: #fff;
  padding: 40px 20px;
  margin: 20px 0;
  margin-bottom: 50px;
  text-align: left;
  flex: 1 1 32%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.product-item h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  flex: 0 0 2%;
}

.product-item h4 {
  font-size: 1.2em;
  margin-bottom: 10px;
  flex: 0 0 15%;
}

.product-item .product-description {
  font-size: 1.2em;
  line-height: 1.8;
  margin-bottom: 10px;
  flex: 0 0 45%;
}

.product-item .product-example {
  font-size: 1.2em;
  line-height: 1.8;
  margin-bottom: 10px;
  flex: 0 0 35%;
}

.analysis-container {
  width: 99%;
  margin: 0 auto;
  background-color: #f5f5f5;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 24px;
}

@media (max-width: 920px) {
  .products-container {
    flex-direction: column;
  }

  .product-item {
    flex: 1 1 100%;
  }

  .product-item h3,
  .product-item h4,
  .product-item .product-description,
  .product-item .product-example {
    flex: 1 1 auto;
    /* Override the fixed heights on smaller screens for better readability */
  }
}

.info-section h4 {
  font-size: 1.6em;
  margin-top: 20px;
  margin-bottom: 10px;
}

.info-section p {
  font-size: 1.2em;
  line-height: 1.8;
  margin-bottom: 10px;
}

.image {
  display: block;
  margin: 20px auto;
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.blockquote-center {
  text-align: center;
  background-color: #f0f0f0; /* 浅灰色背景 */
  padding: 20px;
  border-radius: 10px;
}

blockquote-left {
  text-align: left;
  background-color: #f0f0f0; /* 浅灰色背景 */
  padding: 20px;
  border-radius: 10px;
}

.blockquote-center h3 {
  margin-top: 0;
}

.blockquote-center img {
  display: block;
  margin: 0 auto; /* 居中 */
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.blockquote-center p {
  margin: 10px 0;
}

.blockquote-center a {
  color: #3498db;
  text-decoration: none;
}

.blockquote-center a:hover {
  text-decoration: underline;
}

</style>

<template>
  <div class="MyPortfolioVsAssetsComparison">
    <div>
      <h1>【3】- 误区2 - 委托理财经理或购买基金合集</h1>
    </div>
    <div>
      <h3>
        3.2 误区二：代理人问题—使用基金经理代为理财
      </h3>
      <hr>
      <p>前面已经说过，实现财务自由不是人生奢侈目标，甚至不是可选项。这是一个人<strong>一生必须要经历的阶段</strong>。</p>
      <p>被迫财富自由的时间点是退休。自此之后，用于支持生活的全部负向现金流<strong>要不来自于被动收入，要不来自于储蓄</strong>。</p>
      <p>而“财富自由”后，生活水平的高低，几乎完全取决于退休当时的财富水平，<strong>特别是储蓄水平</strong>。</p>
      <blockquote class="blockquote-center" style="background-color: #f0f0f0; padding: 20px;">
        <h3 style="text-align: center;">晨光笔记2021</h3>
        <p>正值“间隔年”的500强职业经理人 + 国家一级登山运动员，与您分享：【财务积累】干货 + 【户外旅行】攻略 <strong>——晨光</strong></p>
        <p><strong>本期科学投资相应视频：</strong>(<a href="https://www.bilibili.com/video/BV1634y1E7EZ/?spm_id_from=333.999.0.0&vd_source=dc968aa7423843f727e9d8f317282c0f">https://www.bilibili.com/video/BV1634y1E7EZ/?spm_id_from=333.999.0.0&vd_source=dc968aa7423843f727e9d8f317282c0f</a>)</p>
        <figure style="text-align: center;">
          <img src="https://i.postimg.cc/kM6Mgp40/image.jpg" alt="欢迎关注微信公众号《晨光笔记2021》，更多优质内容等您发掘">
          <figcaption><strong>欢迎关注微信公众号《晨光笔记2021》，更多优质内容等您发掘</strong></figcaption>
        </figure>
     </blockquote>
     <p>因此要实现高质量的财富自由、尽早的实现财富自由应该作为每一个人努力的目标。<strong>如何不动声色的加速这一过程，是接下来要讨论的重点</strong>。</p>
    </div>

    <div>
      <h3>教程的目的</h3>
      <hr>

      <p>通过介绍一个诺奖级别的研究成果和演示基于该理论的实践，使读者可以使用普通的投资工具、在不占有大量时间精力的情况下科学、合法的投资实现财富自由或加速财富的积累。<strong>即通过一套Excel工具和方法论找到同等收益率下成本最小的或是同等成本下收益率最高的投资组合进行投资</strong>。</p>
      <blockquote class="blockquote-left" style="background-color: #f0f0f0; padding: 20px;">
        <ul>——此外，制作这样一个教程，也是我对于现年14岁的女儿的一个<strong>承诺</strong>。帮助她在大学前，可以逐步构建“财商”。因此，我会力求使讲解的内容<strong>可以让高中在校生理解</strong>。</ul>
      </blockquote>
      <p><strong>为什么不能找基金经理帮你投资？</strong>在回答此问题之前，我们需要划分一下投资的分类，此处我们将投资划分为两类——<strong>主动投资和被动投资</strong>。接下来我们分别讨论。</p>
    </div>
    <div>
      <h3>主动投资</h3>
      <hr>
      <blockquote class="blockquote-left" style="background-color: #f0f0f0; padding: 20px;">
        <ul>“投资股票坑太多， 我选择一个基金总可以吧？为什么要大动干戈的学习什么《投资组合均值-方差分析》呢？”。</ul>
      </blockquote>
      <p>这可能是绝大多数人，看到这么一个绕口的理论名称并回想起在支付宝、微信中铺天盖地的理财产品的宣传，出现在脑海的第一个<strong>疑问</strong>。</p>
      <blockquote class="blockquote-left" style="background-color: #f0f0f0; padding: 20px;">
        <ul>这里我不得不提一个梗：一个有钱人和一个有经验的人做生意。有经验的人拿走了有钱人的钱，有钱人的拿走对方的经验。</ul>
      </blockquote>
      <p>而在投资理财领域。这番话前半段是对的，有钱人的钱确实被「有经验的人拿走了」；但后半段却不一定对，因为<strong>有钱人并没有拿到</strong>有经验的人的经验。这样的有钱人也叫做<strong>韭菜</strong>。</p>
      <blockquote class="blockquote-left" style="background-color: #f0f0f0; padding: 20px;">
        <ul>我们再做一个思想实验。假设你由于某种好运，得到一块价值连城的宝石原石。众所周知，通过对宝石原石的加工，可以使其价值翻倍—这有点类似于金融市场的<strong>主动投资</strong>，即<strong>通过某些技术活换取大于市场平均水平的收益率</strong>。</ul>
        <ul>恰好你家门有个宝石加工市场。市场中<strong>8%的手艺人</strong>可以通过精湛的技术，使你的宝石增值。而<strong>另外的92%</strong>，则会毁掉你的宝石。你会把你的宝石原石拿到这样的市场去加工吗？</ul>
      </blockquote>
      <p>我相信如果你脑子没有问题，你是绝对不会的—<strong>因为如果你不是天赋异禀，肯定无法看出谁是那8%</strong>。</p>
      <p>接下来，我们把宝石原石换成你用劳动换来的钱，加工市场换成主动型基金市场，基金经理相当于加工珠宝的手艺人。事实上，<strong>很多人的脑子就是出了问题</strong>。</p>
      <blockquote class="blockquote-left" style="background-color: #f0f0f0; padding: 20px;">
        <ul>根据2018年，标普指数官方的研究数据，在过去几年里只有<strong>8%基金经理</strong>跑赢了美国大盘。</ul>
        <ul>类似的研究，在不同国家，不同年份都得到了类似的结论—<strong>只有极少数的基金经理可以真的帮你赚到钱</strong>。而在众多的基金经理中，你很难找到那8%。</ul>
        <ul>因为他们比你有经验，而你，只有钱。而你的钱，就是他们的目标——<strong>换句话说，你就是韭菜</strong>。</ul>
      </blockquote>
    </div>
 <div>
  <h3>被动投资</h3>
  <hr>
  <p>
    以上我们讨论了主动投资基金经理的情况——大概率下你会成韭菜。那么我们再看看<strong>被动投资</strong>会是什么情况呢。
  </p>
  <p>
    所谓被动投资——就是不通过高超技术换取大于市场平均水平的超额，而<strong>只是持有某一个整体市场来谋求平均回报率</strong>。比如找一个基金经理，他帮你持有A股市场市值最大的300家上市公司的股票。
  </p>
  <blockquote class="blockquote-left" style="background-color: #f0f0f0; padding: 20px;">
    <ul>
      我们回到宝石原石的例子，你说：我不当韭菜了，不去加工原石了。我存放起来，<strong>只要能随时间保值换取的与市场平均水平一样的增值即可</strong>。那么现在你面临两个选择。
    </ul>
    <ul>
    <strong>第一种</strong>，放在你自己家，每年看管成本是原石价格的1%。
    </ul>
    <ul>
    <strong>第二种</strong>，放在朋友家里。朋友看在你们关系不错的情分上，收取你2%的费用。这时你又会怎么选呢？
    </ul>
  </blockquote>
  <p>
    话说回到金融市场，你<strong>其实是能够直接买到</strong>的跟踪大盘指数的ETF的(后面我们会具体说什么是ETF，你也可以直接查看关于“ETF”的解释），这<strong>相当于你把原石放在了自己家</strong>。
  </p>
  <p>
    而你从支付宝、银行等渠道买到的跟踪大盘指数被动型基金，<strong>就相当于你把原石放在别人家</strong>。这些被动投资基金经理要收取你2%的费用，是你自己持有大盘指数ETF的价格的一倍。
  </p>
  <p>
    事实是残酷的，很多投资者不知道ETF的存在，也不会知道委托基金经理管理财富和自行持有ETF的效果是一致的，绝大多数投资者在金融市场里就是<strong>被割的韭菜</strong>。
  </p>
  <blockquote class="blockquote-left" style="background-color: #f0f0f0; padding: 20px;">
    <ul>
      毕竟，世界上“有经验的人，要拿走的你钱。”，投资理财界，<strong>92%基金经理拿走你的钱，却不给你任何经验</strong>。否则，他们就没办法挣到第二份钱了。
    </ul>
  </blockquote>
 </div>
 <div>
      <h3>结论</h3>
      <hr>
  <blockquote class="blockquote-left" style="background-color: #f0f0f0; padding: 20px;">
    <ul>
    <strong>第一，不要做主动型投资</strong>，因为你不是那8%也找不到那8%。
    </ul>
  </blockquote>
  <blockquote class="blockquote-left" style="background-color: #f0f0f0; padding: 20px;">
    <ul>
    <strong>第二，只自行购买被动型产品，而不要委托他人</strong>，因为他们做了你自己一样能做的事情，却问你要钱。
    </ul>
  </blockquote>
  <p>
    更何况，上文还提及基金经理是怎么收钱：无论盈亏，按照投资总额乘以一个百分比收取管理费。也就是说，<strong>从机制上，基金经理的收入并不来自于他对于财务的管理能力，而是绑定他的销售能力</strong>。
  </p>
  <blockquote class="blockquote-left" style="background-color: #f0f0f0; padding: 20px;">
    <ul>
      这就像在原石加工市场里，工匠们比拼的是嘴皮子，而不是手上的功夫。以这种机制作为基础的市场，你敢参与吗？
    </ul>
  </blockquote>
  <p>
    好了，你现在会相信普通人是不应该找基金经理来打理自己的钱。那么你可能要问了，<strong>那如果我自己真的什么都不知道，我该怎么办呢？</strong>——请你把教程看完。
  </p>
  <p><strong>to be continued</strong></p>
 </div>
</div>
</template>

<script>
// import MyPortfolioVsAssetsComparison from './MyPortfolioVsAssetsComparison.vue'

export default {
  name: 'MyPortfolioVsAssetsComparison'
// components: {
// MyPortfolioVsAssetsComparison
}

</script>

<style scoped>

.image-container {
  display: flex;
  justify-content: center; /* 居中对齐 */
  align-items: center; /* 垂直居中 */
  gap: 20px; /* 图片间距 */
}

.image-item {
  display: flex;
  flex-direction: column;
  align-items: center; /* 文本和图像居中 */
}

.large img {
  width: 100px; /* 大图片宽度 */
  height: auto; /* 保持图片比例 */
}

.small img {
  width: 200px; /* 小图片宽度 */
  height: auto; /* 保持图片比例 */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  color: #333;
  background-color: #f5f5f7;
}

.text {
  font-size: 1.2em;
  line-height: 1.8;
  margin-bottom: 10px;
}

.home {
  text-align: center;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.hero-section {
  background: #fff;
  padding: 60px 20px;
  margin: 40px 0;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.hero-section h1 {
  font-size: 2.8em;
  margin-bottom: 20px;

}

.hero-section p {
  font-size: 1.4em;
  line-height: 1.8;
}

.problems-section,
.advantages-section,
.case-section,
.info-section {
  background: #fff;
  padding: 30px 10px;
  margin: 40px 0;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.problems-section h3,
.advantages-section h3,
.case-section h3,
.info-section h3 {
  font-size: 2.2em;
  margin-bottom: 20px;
  line-height: 1.5em;
}

.problems-section ul,
.advantages-section ul,
.case-section ul {
  list-style-type: disc;
  padding-left: 20px;
}

.problems-section li,
.advantages-section li,
.case-section li {
  font-size: 1.2em;
  line-height: 1.8;
  margin-bottom: 10px;
  list-style-type: disc;
  padding-left: 20px;
}

.products-section {
  background: #fff;
  padding: 60px 20px;
  margin: 40px 0;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.products-section h2 {
  font-size: 2.4em;
  margin-bottom: 20px;
}

.products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  min-height: 1000px;
}

.product-item {
  background: #fff;
  padding: 40px 20px;
  margin: 20px 0;
  margin-bottom: 50px;
  text-align: left;
  flex: 1 1 32%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.product-item h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  flex: 0 0 2%;
}

.product-item h4 {
  font-size: 1.2em;
  margin-bottom: 10px;
  flex: 0 0 15%;
}

.product-item .product-description {
  font-size: 1.2em;
  line-height: 1.8;
  margin-bottom: 10px;
  flex: 0 0 45%;
}

.product-item .product-example {
  font-size: 1.2em;
  line-height: 1.8;
  margin-bottom: 10px;
  flex: 0 0 35%;
}

.analysis-container {
  width: 99%;
  margin: 0 auto;
  background-color: #f5f5f5;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 24px;
}

@media (max-width: 920px) {
  .products-container {
    flex-direction: column;
  }

  .product-item {
    flex: 1 1 100%;
  }

  .product-item h3,
  .product-item h4,
  .product-item .product-description,
  .product-item .product-example {
    flex: 1 1 auto;
    /* Override the fixed heights on smaller screens for better readability */
  }
}

.info-section h4 {
  font-size: 1.6em;
  margin-top: 20px;
  margin-bottom: 10px;
}

.info-section p {
  font-size: 1.2em;
  line-height: 1.8;
  margin-bottom: 10px;
}

.image {
  display: block;
  margin: 20px auto;
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.blockquote-center {
  text-align: center;
  background-color: #f0f0f0; /* 浅灰色背景 */
  padding: 20px;
  border-radius: 10px;
}

blockquote-left {
  text-align: left;
  background-color: #f0f0f0; /* 浅灰色背景 */
  padding: 20px;
  border-radius: 10px;
}

.blockquote-center h3 {
  margin-top: 0;
}

.blockquote-center img {
  display: block;
  margin: 0 auto; /* 居中 */
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.blockquote-center p {
  margin: 10px 0;
}

.blockquote-center a {
  color: #3498db;
  text-decoration: none;
}

.blockquote-center a:hover {
  text-decoration: underline;
}

</style>

<template>
  <div class="home">
    <div class="content">
      <section class="hero-section">
        <h1>【2】-误区1-忽视成本和性价比，只关注收益率</h1><br>
        <p>
          普通人常见的投资误区主要分为<strong>三大类</strong>，下面我们将分别进行说明：
        </p>
      </section>
      <section class="problems-section">
        <h3 style="text-align: center;">晨光笔记2021</h3>
        <p style="text-align: center;">正值“间隔年”的500强职业经理人 + 国家一级登山运动员，与您分享：【财务积累】干货 + 【户外旅行】攻略 <strong>——晨光</strong></p>
        <p style="text-align: center;"><strong>本期科学投资相应视频：</strong><br>
         <a :href="url" target="_blank">
           <img src="@/assets/2-1.png" class="image"/>
         </a>
        </p>
        <figure style="text-align: center;">
          <img src="https://i.postimg.cc/kM6Mgp40/image.jpg" alt="欢迎关注微信公众号《晨光笔记2021》，更多优质内容等您发掘">
          <figcaption><strong>欢迎关注微信公众号《晨光笔记2021》，更多优质内容等您发掘</strong></figcaption>
        </figure>
      </section>
      <section class="problems-section">
      <h2>3.1 普通人常见误区一：忽视成本和性价比</h2>
      <hr>
      <ul>
          <li><strong>3.1.1 抛开成本谈收益都是耍流氓</strong><br>此处必须要先强调一下成本和性价比的观念。<br>
            假设你小有积蓄，而闲置资金可以用于投资。对，这里说的是假设。因为<strong>有存款并不代表你真的有闲置资金</strong>。这个我们会在后面的章节详细讨论。<br>
            你开始投资了。你的直觉可能指引你打开支付宝或其他金融App，按照收益率对可以投资的产品进行排序。你还会隐约的认为：投资就是把闲钱放在收益率尽可能高的产品上。<strong>其实这样做是错误的</strong>。<br>
            </li><br>
      </ul>
      <h2 style="text-align: center;">我们来做一个思维实验。你面前有一辆八手夏利和一辆保时捷，你会购买哪一辆车呢？</h2><br>
    </section>
    <div style="display: flex; justify-content: center; align-items: center; gap: 20px;">
       <img src="@/assets/2-2.png" alt="八手夏利" style="width: 300px; height: 200px; object-fit: cover;"/>
       <img src="@/assets/2-3.jpg" alt="保时捷" style="width: 300px; height: 200px; object-fit: cover;"/>
    </div>
    <section class="problems-section">
      <ul>
        <p class="text">
          你会单纯因为保时捷比夏利好，就购买保时捷吗？不，你会衡量，你为了购买夏利和保时捷要各付出多少成本，然后结合两者的收益进行评估后再做决定。这就是我们生活里常说的<strong>性价比</strong>。<br>
        </p>
        <p class="text">
          在某种情况下，保时捷的性价比会高于夏利。比如夏利1万，而保时捷只行驶了1万公里却只标价20万。如果此时对保时捷动心了，你就会衡量自己是否有足够的闲钱去购买保时捷，也就是<strong>考察自己的承受能力</strong>。<br>
        </p>
        <p class="text">
          总结一下：我们在一般情况下做决策是会考虑<strong>两个因素</strong>：<br>
        </p>
        <li>第一，<strong>性价比</strong>。</li>
        <li>第二，<strong>自身承受能力</strong>。</li>
        <p class="text">
          这两个因素都与成本直接相关。所谓<strong>抛开成本谈收益都是耍流氓</strong>。平常我们做决策的时候，成本是显而易见或是很容易进行分析评估的。<br>
        </p>
        <p class="text">
          然而在投资的时候，很多人就忘记了这两个原则。比如将投资产品按照收益率排序选择最高的，却<strong>对于投资的成本视而不见。</strong><br>
        </p>
      </ul>
    </section>
    <section class="problems-section">
      <ul>
          <li><strong>3.1.2 专业人士的秘密</strong><br>
            </li><br>
        <p class="text">
          其实这也不能责难投资者。投资者之所以有意无意忘记了考虑投资品的性价比，是因为少有<strong>专业人员</strong>告诉你投资的成本是如何衡量的。
        </p>
        <p class="text">
          毕竟当你知道<strong>真相</strong>后，是绝对不会把钱交给这些“专业人员”的。（这里<strong>再次强调</strong>，你是一个普通人，不是超级富豪。普通人和超级富豪通过投资解决问题的偏好是截然不同的。前者偏向于保值增值，换取额外的被动收入。而富豪们天生就是靠被动收入活着的人，他们需要解决的问题是财富安全和财富传承。）
        </p>
        <p class="text">
          “专业人员”有意无意向你保守的秘密是：<strong>投资是有成本的，成本是可以准确衡量的</strong>。就像你买东西，东西是有价格的，价格是用数字准确标明的。 <strong>投资成本是波动性，波动性可以用数字——方差来衡量</strong>。
        </p>
        <p class="text">
          <strong><em>波动性（即风险）=方差：</em></strong>
        </p>
        <a :href="url" target="_blank">
           <img src="@/assets/2-4.png" class="image"/>
         </a>
         <p class="text">
          如果<strong><em>Xi </em>表示投资品涨跌幅，<em>u </em>表示投资品涨跌均值</strong>，则<strong>方差公式=投资品的方差公式</strong>
        </p>
        <p class="text">
          有了这个关于成本的准确度量，那么一个投资品的性价比，和个人的承受能力就变得显而易见。
        </p>
      </ul>
    </section>
    <section class="problems-section">
      <ul>
        <p class="text">
          例如：一个产品的<strong>预期收益率是每月0.32%，年化收率是：3.89%。</strong>这是基金经理们愿意让你看到的。
        </p>
        <p class="text">
          可你却很难，不，应该说是几乎不可能找到该产品的成本，即产品的波动性是多少。
        </p>
        <p class="text">
        事实上，通过仔细阅读产品说明书，我们发现基金经理投资的是<strong>香港恒生大盘</strong>。
        根据历史数据可以计算出来，
        <strong>当然基金经理不会告诉你这个产品的月波动性是：4.55%。</strong>
        我们来计算一下，大致相当于你每投入100份成本，换取了7份收益，<strong>性价比大约是7/100</strong>。
        </p>
        <p class="text">
        具体计算如下：
        </p>
        <a :href="url" target="_blank">
           <img src="@/assets/2-5.png" class="image"/>
         </a>
      </ul>
    </section>
    <section class="problems-section">
      <ul>
        <p class="text">
          没有对比就没有伤害。对比一下，<strong>国债</strong><strong>收益率为0.3%，波动性是0.8%。</strong>那么计算可得投资<strong>国债的性价比大约是：38/100。</strong>
        </p>
        <p class="text">
        具体计算如下：
        </p>
        <a :href="url" target="_blank">
           <img src="@/assets/2-6.png" class="image"/>
         </a>
      </ul>
    </section>
    <section class="problems-section">
      <ul>
        <p class="text">
        不知道面对这样的数字，<strong>你会选择哪个产品？</strong>
        与国债相比，同样花销100份的成本，一个是获得7份收益，一个获得38份收益。
        并且收益率是类似的，都在0.3%左右。
        </p>
      </ul>
      <h3 style="text-align: center;">没有对比就没有伤害!</h3><br>
    </section>
    <section class="problems-section">
      <ul>
        <p class="text">
          另外一个问题我们这里就不展开讨论了，简单提一下。
        </p>
        <p class="text">
          假设有一款产品性价比虽然低，但是回报率确实是高。是否要投资这个产品，
        <strong>就要看这个波动性你是否能接受</strong>。
        就像买车一样，保时捷虽好但性价比低，但是车就是好。
        那为什么买保时捷的还是少数呢？——<strong>因为买不起</strong>。
        </p>
        <p class="text">
          但在投资领域吊诡的是：投资者似乎都不在意自己是否“承担的起”。
        当然你购买保时捷的时候，是可以明确看到价格的。
        <strong>你在投资的很多时候，“成本”是不清晰的。因此你就只能拆盲盒了</strong>。
        </p>
        <p class="text">
          <strong>除此之外，基金经理还不会告诉你的是</strong>:
        预期3.89%的年化收益中，他要拿走1-2%，占全部收益的25%~50%。
        要知道，<strong>无论这一年赚钱与否，基金经理都要拿走你总投资额的1-2%。
        而你自己购买恒生指数ETF，却没有人跟你分享收益。</strong>
        </p>
      </ul>
    </section>
    <h1>to be continued</h1>
 </div>
</div>
</template>

<script>
// import MyPortfolioVsAssetsComparison from './MyPortfolioVsAssetsComparison.vue'
export default {
  name: 'VueTwo',
  data () {
    return {
      url: 'https://www.bilibili.com/video/BV1CT4y1q7AX/?spm_id_from=333.999.0.0',
      imagePath: require('@/assets/2-1.png')
    }
  }
}
</script>

<style scoped>
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  color: #333;
  background-color: #f5f5f7;
}

.text {
  font-size: 1.2em;
  line-height: 1.8;
  margin-bottom: 10px;
}

.home {
  text-align: center;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.hero-section {
  background: #fff;
  padding: 60px 20px;
  margin: 40px 0;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.hero-section h1 {
  font-size: 2.8em;
  margin-bottom: 20px;

}

.hero-section p {
  font-size: 1.4em;
  line-height: 1.8;
}

.problems-section,
.advantages-section,
.case-section,
.info-section {
  background: #fff;
  padding: 30px 10px;
  margin: 40px 0;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.problems-section h3,
.advantages-section h3,
.case-section h3,
.info-section h3 {
  font-size: 2.2em;
  margin-bottom: 20px;
  line-height: 1.5em;
}

.problems-section ul,
.advantages-section ul,
.case-section ul {
  list-style-type: disc;
  padding-left: 20px;
}

.problems-section li,
.advantages-section li,
.case-section li {
  font-size: 1.2em;
  line-height: 1.8;
  margin-bottom: 10px;
  list-style-type: disc;
  padding-left: 20px;
}

.products-section {
  background: #fff;
  padding: 60px 20px;
  margin: 40px 0;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.products-section h2 {
  font-size: 2.4em;
  margin-bottom: 20px;
}

.products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  min-height: 1000px;
}

.product-item {
  background: #fff;
  padding: 40px 20px;
  margin: 20px 0;
  margin-bottom: 50px;
  text-align: left;
  flex: 1 1 32%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.product-item h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  flex: 0 0 2%;
}

.product-item h4 {
  font-size: 1.2em;
  margin-bottom: 10px;
  flex: 0 0 15%;
}

.product-item .product-description {
  font-size: 1.2em;
  line-height: 1.8;
  margin-bottom: 10px;
  flex: 0 0 45%;
}

.product-item .product-example {
  font-size: 1.2em;
  line-height: 1.8;
  margin-bottom: 10px;
  flex: 0 0 35%;
}

.analysis-container {
  width: 99%;
  margin: 0 auto;
  background-color: #f5f5f5;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 24px;
}

@media (max-width: 920px) {
  .products-container {
    flex-direction: column;
  }

  .product-item {
    flex: 1 1 100%;
  }

  .product-item h3,
  .product-item h4,
  .product-item .product-description,
  .product-item .product-example {
    flex: 1 1 auto;
    /* Override the fixed heights on smaller screens for better readability */
  }
}

.info-section h4 {
  font-size: 1.6em;
  margin-top: 20px;
  margin-bottom: 10px;
}

.info-section p {
  font-size: 1.2em;
  line-height: 1.8;
  margin-bottom: 10px;
}

.image {
  display: block;
  margin: 20px auto;
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.blockquote-center {
  text-align: center;
  background-color: #f0f0f0; /* 浅灰色背景 */
  padding: 20px;
  border-radius: 10px;
}

blockquote-left {
  text-align: left;
  background-color: #f0f0f0; /* 浅灰色背景 */
  padding: 20px;
  border-radius: 10px;
}

.blockquote-center h3 {
  margin-top: 0;
}

.blockquote-center img {
  display: block;
  margin: 0 auto; /* 居中 */
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.blockquote-center p {
  margin: 10px 0;
}

.blockquote-center a {
  color: #3498db;
  text-decoration: none;
}

.blockquote-center a:hover {
  text-decoration: underline;
}

</style>

import Vue from 'vue'
import Router from 'vue-router'
import Vue1 from '@/components/Vue1.vue'
import Vue2 from '@/components/Vue2.vue'
import Vue3 from '@/components/Vue3.vue'

Vue.use(Router)

export default new Router({
  mode: 'history', // 确保使用 history 模式
  routes: [
    { path: '/Vue1', component: Vue1 },
    { path: '/vue2', component: Vue2 },
    { path: '/vue3', component: Vue3 }
  ]
})

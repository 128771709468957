var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"content"},[_vm._m(0),_c('section',{staticClass:"problems-section"},[_c('h3',{staticStyle:{"text-align":"center"}},[_vm._v("晨光笔记2021")]),_vm._m(1),_c('p',{staticStyle:{"text-align":"center"}},[_c('strong',[_vm._v("本期科学投资相应视频：")]),_c('br'),_c('a',{attrs:{"href":_vm.url,"target":"_blank"}},[_c('img',{staticClass:"image",attrs:{"src":require("@/assets/2-1.png")}})])]),_vm._m(2)]),_vm._m(3),_vm._m(4),_vm._m(5),_c('section',{staticClass:"problems-section"},[_c('ul',[_vm._m(6),_c('br'),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_c('a',{attrs:{"href":_vm.url,"target":"_blank"}},[_c('img',{staticClass:"image",attrs:{"src":require("@/assets/2-4.png")}})]),_vm._m(11),_c('p',{staticClass:"text"},[_vm._v(" 有了这个关于成本的准确度量，那么一个投资品的性价比，和个人的承受能力就变得显而易见。 ")])])]),_c('section',{staticClass:"problems-section"},[_c('ul',[_vm._m(12),_c('p',{staticClass:"text"},[_vm._v(" 可你却很难，不，应该说是几乎不可能找到该产品的成本，即产品的波动性是多少。 ")]),_vm._m(13),_c('p',{staticClass:"text"},[_vm._v(" 具体计算如下： ")]),_c('a',{attrs:{"href":_vm.url,"target":"_blank"}},[_c('img',{staticClass:"image",attrs:{"src":require("@/assets/2-5.png")}})])])]),_c('section',{staticClass:"problems-section"},[_c('ul',[_vm._m(14),_c('p',{staticClass:"text"},[_vm._v(" 具体计算如下： ")]),_c('a',{attrs:{"href":_vm.url,"target":"_blank"}},[_c('img',{staticClass:"image",attrs:{"src":require("@/assets/2-6.png")}})])])]),_vm._m(15),_vm._m(16),_c('h1',[_vm._v("to be continued")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"hero-section"},[_c('h1',[_vm._v("【2】-误区1-忽视成本和性价比，只关注收益率")]),_c('br'),_c('p',[_vm._v(" 普通人常见的投资误区主要分为"),_c('strong',[_vm._v("三大类")]),_vm._v("，下面我们将分别进行说明： ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"center"}},[_vm._v("正值“间隔年”的500强职业经理人 + 国家一级登山运动员，与您分享：【财务积累】干货 + 【户外旅行】攻略 "),_c('strong',[_vm._v("——晨光")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('figure',{staticStyle:{"text-align":"center"}},[_c('img',{attrs:{"src":"https://i.postimg.cc/kM6Mgp40/image.jpg","alt":"欢迎关注微信公众号《晨光笔记2021》，更多优质内容等您发掘"}}),_c('figcaption',[_c('strong',[_vm._v("欢迎关注微信公众号《晨光笔记2021》，更多优质内容等您发掘")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"problems-section"},[_c('h2',[_vm._v("3.1 普通人常见误区一：忽视成本和性价比")]),_c('hr'),_c('ul',[_c('li',[_c('strong',[_vm._v("3.1.1 抛开成本谈收益都是耍流氓")]),_c('br'),_vm._v("此处必须要先强调一下成本和性价比的观念。"),_c('br'),_vm._v(" 假设你小有积蓄，而闲置资金可以用于投资。对，这里说的是假设。因为"),_c('strong',[_vm._v("有存款并不代表你真的有闲置资金")]),_vm._v("。这个我们会在后面的章节详细讨论。"),_c('br'),_vm._v(" 你开始投资了。你的直觉可能指引你打开支付宝或其他金融App，按照收益率对可以投资的产品进行排序。你还会隐约的认为：投资就是把闲钱放在收益率尽可能高的产品上。"),_c('strong',[_vm._v("其实这样做是错误的")]),_vm._v("。"),_c('br')]),_c('br')]),_c('h2',{staticStyle:{"text-align":"center"}},[_vm._v("我们来做一个思维实验。你面前有一辆八手夏利和一辆保时捷，你会购买哪一辆车呢？")]),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","gap":"20px"}},[_c('img',{staticStyle:{"width":"300px","height":"200px","object-fit":"cover"},attrs:{"src":require("@/assets/2-2.png"),"alt":"八手夏利"}}),_c('img',{staticStyle:{"width":"300px","height":"200px","object-fit":"cover"},attrs:{"src":require("@/assets/2-3.jpg"),"alt":"保时捷"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"problems-section"},[_c('ul',[_c('p',{staticClass:"text"},[_vm._v(" 你会单纯因为保时捷比夏利好，就购买保时捷吗？不，你会衡量，你为了购买夏利和保时捷要各付出多少成本，然后结合两者的收益进行评估后再做决定。这就是我们生活里常说的"),_c('strong',[_vm._v("性价比")]),_vm._v("。"),_c('br')]),_c('p',{staticClass:"text"},[_vm._v(" 在某种情况下，保时捷的性价比会高于夏利。比如夏利1万，而保时捷只行驶了1万公里却只标价20万。如果此时对保时捷动心了，你就会衡量自己是否有足够的闲钱去购买保时捷，也就是"),_c('strong',[_vm._v("考察自己的承受能力")]),_vm._v("。"),_c('br')]),_c('p',{staticClass:"text"},[_vm._v(" 总结一下：我们在一般情况下做决策是会考虑"),_c('strong',[_vm._v("两个因素")]),_vm._v("："),_c('br')]),_c('li',[_vm._v("第一，"),_c('strong',[_vm._v("性价比")]),_vm._v("。")]),_c('li',[_vm._v("第二，"),_c('strong',[_vm._v("自身承受能力")]),_vm._v("。")]),_c('p',{staticClass:"text"},[_vm._v(" 这两个因素都与成本直接相关。所谓"),_c('strong',[_vm._v("抛开成本谈收益都是耍流氓")]),_vm._v("。平常我们做决策的时候，成本是显而易见或是很容易进行分析评估的。"),_c('br')]),_c('p',{staticClass:"text"},[_vm._v(" 然而在投资的时候，很多人就忘记了这两个原则。比如将投资产品按照收益率排序选择最高的，却"),_c('strong',[_vm._v("对于投资的成本视而不见。")]),_c('br')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('strong',[_vm._v("3.1.2 专业人士的秘密")]),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text"},[_vm._v(" 其实这也不能责难投资者。投资者之所以有意无意忘记了考虑投资品的性价比，是因为少有"),_c('strong',[_vm._v("专业人员")]),_vm._v("告诉你投资的成本是如何衡量的。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text"},[_vm._v(" 毕竟当你知道"),_c('strong',[_vm._v("真相")]),_vm._v("后，是绝对不会把钱交给这些“专业人员”的。（这里"),_c('strong',[_vm._v("再次强调")]),_vm._v("，你是一个普通人，不是超级富豪。普通人和超级富豪通过投资解决问题的偏好是截然不同的。前者偏向于保值增值，换取额外的被动收入。而富豪们天生就是靠被动收入活着的人，他们需要解决的问题是财富安全和财富传承。） ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text"},[_vm._v(" “专业人员”有意无意向你保守的秘密是："),_c('strong',[_vm._v("投资是有成本的，成本是可以准确衡量的")]),_vm._v("。就像你买东西，东西是有价格的，价格是用数字准确标明的。 "),_c('strong',[_vm._v("投资成本是波动性，波动性可以用数字——方差来衡量")]),_vm._v("。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text"},[_c('strong',[_c('em',[_vm._v("波动性（即风险）=方差：")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text"},[_vm._v(" 如果"),_c('strong',[_c('em',[_vm._v("Xi ")]),_vm._v("表示投资品涨跌幅，"),_c('em',[_vm._v("u ")]),_vm._v("表示投资品涨跌均值")]),_vm._v("，则"),_c('strong',[_vm._v("方差公式=投资品的方差公式")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text"},[_vm._v(" 例如：一个产品的"),_c('strong',[_vm._v("预期收益率是每月0.32%，年化收率是：3.89%。")]),_vm._v("这是基金经理们愿意让你看到的。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text"},[_vm._v(" 事实上，通过仔细阅读产品说明书，我们发现基金经理投资的是"),_c('strong',[_vm._v("香港恒生大盘")]),_vm._v("。 根据历史数据可以计算出来， "),_c('strong',[_vm._v("当然基金经理不会告诉你这个产品的月波动性是：4.55%。")]),_vm._v(" 我们来计算一下，大致相当于你每投入100份成本，换取了7份收益，"),_c('strong',[_vm._v("性价比大约是7/100")]),_vm._v("。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text"},[_vm._v(" 没有对比就没有伤害。对比一下，"),_c('strong',[_vm._v("国债")]),_c('strong',[_vm._v("收益率为0.3%，波动性是0.8%。")]),_vm._v("那么计算可得投资"),_c('strong',[_vm._v("国债的性价比大约是：38/100。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"problems-section"},[_c('ul',[_c('p',{staticClass:"text"},[_vm._v(" 不知道面对这样的数字，"),_c('strong',[_vm._v("你会选择哪个产品？")]),_vm._v(" 与国债相比，同样花销100份的成本，一个是获得7份收益，一个获得38份收益。 并且收益率是类似的，都在0.3%左右。 ")])]),_c('h3',{staticStyle:{"text-align":"center"}},[_vm._v("没有对比就没有伤害!")]),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"problems-section"},[_c('ul',[_c('p',{staticClass:"text"},[_vm._v(" 另外一个问题我们这里就不展开讨论了，简单提一下。 ")]),_c('p',{staticClass:"text"},[_vm._v(" 假设有一款产品性价比虽然低，但是回报率确实是高。是否要投资这个产品， "),_c('strong',[_vm._v("就要看这个波动性你是否能接受")]),_vm._v("。 就像买车一样，保时捷虽好但性价比低，但是车就是好。 那为什么买保时捷的还是少数呢？——"),_c('strong',[_vm._v("因为买不起")]),_vm._v("。 ")]),_c('p',{staticClass:"text"},[_vm._v(" 但在投资领域吊诡的是：投资者似乎都不在意自己是否“承担的起”。 当然你购买保时捷的时候，是可以明确看到价格的。 "),_c('strong',[_vm._v("你在投资的很多时候，“成本”是不清晰的。因此你就只能拆盲盒了")]),_vm._v("。 ")]),_c('p',{staticClass:"text"},[_c('strong',[_vm._v("除此之外，基金经理还不会告诉你的是")]),_vm._v(": 预期3.89%的年化收益中，他要拿走1-2%，占全部收益的25%~50%。 要知道，"),_c('strong',[_vm._v("无论这一年赚钱与否，基金经理都要拿走你总投资额的1-2%。 而你自己购买恒生指数ETF，却没有人跟你分享收益。")])])])])
}]

export { render, staticRenderFns }
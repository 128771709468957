<template>
  <div>
    <h1>课程目录</h1>
    <ul>
      <li><router-link to="/Vue1">第一课：Vue1</router-link></li>
      <li><router-link to="/vue2">第二课：Vue2</router-link></li>
      <li><router-link to="/vue3">第三课：Vue3</router-link></li>
      <li><router-link to="/vue4">第四课：Vue4</router-link></li>
      <li><router-link to="/vue5">第五课：Vue5</router-link></li>
    </ul>
    <router-view></router-view>
  </div>
</template>

<script>
// import MyPortfolioVsAssetsComparison from './components/MyPortfolioVsAssetsComparison.vue'
export default {
  name: 'App'
}
</script>

<style scoped>

</style>
